import React from "react"
import "../components/layout.css"
import styled from "styled-components"

import staticdata from "../../staticdata.json"
import Cell from "../components/Cell"
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionCellGroup = styled.div`
  margin: 50px 40px 80px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  justify-items: center;

  @media (max-width: 1060px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ProjectsPage = () => (
  <Layout>
    <Seo title="List of projects" />
    <div className="HeroPage">
      <h1>Nuestros Proyectos</h1>
      <p>
        La experiencias es respaldada por los proyectos realizados por VERPAR
      </p>
    </div>
    <div className="SectionProjects">
      <h3>Proyectos Hidroeléctricos</h3>
      <SectionCellGroup>
        <Cell
          title={staticdata.cells[0].title}
          client={staticdata.cells[0].client}
        />
        <Cell
          title={staticdata.cells[1].title}
          client={staticdata.cells[1].client}
        />
        <Cell
          title={staticdata.cells[2].title}
          client={staticdata.cells[2].client}
        />
        <Cell
          title={staticdata.cells[3].title}
          client={staticdata.cells[3].client}
        />
        <Cell
          title={staticdata.cells[6].title}
          client={staticdata.cells[6].client}
        />
        <Cell
          title={staticdata.cells[7].title}
          client={staticdata.cells[7].client}
        />
        <Cell
          title={staticdata.cells[9].title}
          client={staticdata.cells[9].client}
        />
        <Cell
          title={staticdata.cells[10].title}
          client={staticdata.cells[10].client}
        />
      </SectionCellGroup>
      <h3>Plantas de Bombeo</h3>
      <SectionCellGroup>
        <Cell
          title={staticdata.cells[4].title}
          client={staticdata.cells[4].client}
        />
        <Cell
          title={staticdata.cells[5].title}
          client={staticdata.cells[5].client}
        />
      </SectionCellGroup>
      <h3>Presas y Sifones</h3>
      <SectionCellGroup>
        <Cell
          title={staticdata.cells[8].title}
          client={staticdata.cells[8].client}
        />
      </SectionCellGroup>
    </div>
  </Layout>
)

export default ProjectsPage
